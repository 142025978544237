import { useStaticQuery, graphql } from "gatsby"

export const useChildren = (parent: string) => {
  const data = useStaticQuery(graphql`
    query ChildrenQueyry {
      pages: allMarkdownRemark {
        edges {
          node {
            id
            frontmatter {
              bgImage
              level
              metaDesc
              metaTitle
              path
              parent
              sidebar
              title
              slug
              type
            }
          }
        }
      }
    }
  `)

  const parents = data.pages.edges.filter(
    (n: any) => n.node.frontmatter.parent === parent
  )

  return parents
}
