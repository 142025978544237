import React from "react"
import { Box } from "@chakra-ui/core"

type Props = {}

export const HeaderWithColor: React.FC<Props> = ({ children }) => {
  return (
    <Box
      bg="action"
      m="0rem"
      p="0rem"
      padding="70px 0"
      textAlign="center"
      marginY="3rem"
    >
      {children}
    </Box>
  )
}
