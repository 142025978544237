import { Box, Divider, Link, Tag, Text } from "@chakra-ui/core"
import React from "react"

export const UrakkaAd = () => {
  return (
    <Box color="blue" textAlign="center">
      <Divider />
      <Box paddingLeft="0.3rem" marginY="0.3rem">
        <Link href="https://www.omaurakka.fi/" target="_blank">
          <Box>
            <Box
              backgroundColor="green"
              m="0rem auto"
              p="1rem"
              width="130px"
              borderRadius="3px"
            >
              Mainos
            </Box>

            <Text>
              Kilpailuta paras kattoremontti ---
              <Text as="span" fontWeight="500">
                Omaurakka.fi
              </Text>
            </Text>
          </Box>
        </Link>
      </Box>
      <Divider />
    </Box>
  )
}
