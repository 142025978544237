import { Box } from "@chakra-ui/core"
import { Link } from "gatsby"
import React from "react"

type Props = {
  name: string
  link: string
  isFinal: boolean
}

export const Breadcrumb: React.FC<Props> = ({ name, link, isFinal }) => {
  const capitalizedName = name[0].toUpperCase() + name.slice(1)
  return (
    <Box bg="white">
      <Link to={link}>
        <Box fontWeight={isFinal ? 800 : 300} marginX="0.2rem" color="black">
          {isFinal ? `${" "}${capitalizedName}` : `${capitalizedName}  > `}
        </Box>
      </Link>
    </Box>
  )
}
