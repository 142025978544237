import React, { useState } from "react"
import { Box, Heading } from "@chakra-ui/core"
import { Layout } from "../../components/layout/Layout"
import { graphql } from "gatsby"
import DatePicker, { registerLocale } from "react-datepicker"
import { useDate } from "../../hooks/useDate"
import "react-datepicker/dist/react-datepicker.css"
import rehypeReact from "rehype-react"
import ReactMarkdown from "react-markdown"
import { TextContainer } from "../../components/containers/textContainer/TextContainer"
import { SidebarRight } from "../../components/containers/sideBars/SidebarRight"
import { HeaderWithColor } from "../../components/headers/HeaderWithColor"
import { Seo } from "../../components/seo/Seo"
import { useChildren } from "../../hooks/useChildren"
import { MainContainer } from "../../components/containers/mainContainer/MainContainer"
import { FixedSidebar } from "../../components/containers/sideBars/fixedSidebar/FixedSidebar"
import { City } from "../../types/City"
import { CompareHeader } from "../../components/compare/header/CompareHeader"
import placeSuggestions from "../../data/placeSuggestions.json"
import { Suggestion } from "../../types/Suggestion"
import "./theme.css"
import fi from "date-fns/locale/fi"
import { Breadcumbs } from "../../components/navigation/breadcrumbs/Breadcumbs"
import { SuggestContainer } from "../../components/suggest/SuggestContainer"
import { CVad } from "../../components/ads/CvAd"
import { UrakkaAd } from "../../components/ads/UrakkaAd"

registerLocale("fi", fi)

type Props = {
  pageContext: { city: City; slug: string }
  data: {
    cityParts: { edges: { node: City }[] }
    cities: { edges: { node: City }[] }
  }
}

const Template: React.FC<Props> = ({ pageContext, data }) => {
  const {
    text,
    city,
    bookingId,
    hotelsCount,
    id,
    lomarengasId,
    parentCity,
    region,
  } = pageContext["city"]

  const { slug } = pageContext
  const cityCapitalized = city[0].toUpperCase() + city.slice(1)
  const placeData = placeSuggestions.filter(
    item => item.name === city.toLowerCase()
  )[0]

  const suggestionCityParts = data.cityParts.edges.map(({ node }) => {
    return {
      name: node.city,
      link: `/${node.region}/${node.parentCity}/${node.city}`,
    }
  })

  const suggestionCities = data.cities.edges.map(({ node }) => {
    if (node.parentCity)
      return {
        name: node.city,
        link: `/${node.region}/${node.parentCity}/${node.city}`,
      }
    return {
      name: node.city,
      link: `/${node.region}/${node.city}`,
    }
  })

  const [selectedPlace, setSelectedPlace] = useState(
    city.toLowerCase() === "suomi" ? "" : cityCapitalized
  )
  const [selectedId, setSelectedId] = useState("")
  const [suggestions, setSuggestions] = useState<Suggestion[]>([])
  const [showResults, setShowResults] = useState(false)
  const [personCount, setPersonCount] = useState("")

  const arriveDayHook = useDate()
  const leaveDayHook = useDate()
  const handleChangeDate = (date: Date) => {
    arriveDayHook.changeDate(date)
  }

  const children = useChildren(slug)

  //   const productSnippets = products.map((p, i) => {
  //     return `{
  //   "@context" : "http://schema.org",
  //   "@type" : "Product",
  //   "name" : "${p.node.name}",
  //   "image" : "https:${p.node.img.fluid.src}",
  //   "url" : "${p.node.link}",
  //   "offers" : {
  //     "@type" : "Offer",
  //     "price" : "${p.node.price}"
  //   },
  //   "aggregateRating" : {
  //     "@type" : "AggregateRating",
  //     "ratingValue" : "${p.node.rate}",
  //     "ratingCount" : "1"
  //   }
  // } ${i + 1 < products.length ? "," : ""}`
  //   })

  return (
    <Layout>
      <Seo
        title={`Paras majoitus ${cityCapitalized} - Vuokramökit, hotellit ja muut majoitukset`}
        desc={`Varaa edullinen majoitus kohteessa ${cityCapitalized}. Kauttamme vertaat parhaat hotellit, vuokramökit, huoneistohotellit ja muut suositut majoitusmuodot. Katso täältä ja varaa jo tänään.`}
      />
      <CompareHeader
        bookingId={bookingId}
        lomarengasId={lomarengasId}
        place={city}
        region={false}
      >
        <Breadcumbs />
      </CompareHeader>
      <CVad />
      <UrakkaAd />

      <SuggestContainer
        title="Voit tutustua myös näihin kohteisiin"
        places={suggestionCityParts}
      />
      <HeaderWithColor>
        <Heading as="h3" color="white" p="1rem 0rem" m="0rem">
          Lisätietoja paikasta {cityCapitalized}
        </Heading>
      </HeaderWithColor>
      <MainContainer>
        <Box flex="8">
          <TextContainer>
            <Heading as="h4">{cityCapitalized}</Heading>
            <ReactMarkdown>{text}</ReactMarkdown>
            <SuggestContainer
              title={`Muita mielenkiihtoisia majoituskohteita maakunnassa${" "}
              ${
                pageContext.city.region[0].toUpperCase() +
                pageContext.city.region.slice(1)
              }`}
              places={suggestionCities}
            />
          </TextContainer>
        </Box>

        {/* <SidebarRight>
          <FixedSidebar>
            <Heading as="h3">Sivupalkki</Heading>
          </FixedSidebar>
        </SidebarRight> */}
      </MainContainer>
      {/* <!-- JSON-LD markup generated by Google Structured Data Markup Helper. --> */}
      {/* <script type="application/ld+json">[{productSnippets}]</script> */}
    </Layout>
  )
}

export const query = graphql`
  query($region: String!, $parentCity: String!) {
    cities: allMongodbSuomimajoitusCities(filter: { region: { eq: $region } }) {
      edges {
        node {
          city
          parentCity
          region
        }
      }
    }
    cityParts: allMongodbSuomimajoitusCities(
      filter: { parentCity: { eq: $parentCity } }
    ) {
      edges {
        node {
          city
          parentCity
          region
        }
      }
    }
    # allMarkdownRemark(
    #   filter: { frontmatter: { type: { eq: "city" } } }
    #   sort: { fields: frontmatter___name }
    # ) {
    #   edges {
    #     node {
    #       frontmatter {
    #         filter
    #         path
    #         name
    #       }
    #     }
    #   }
    # }
  }
`

export default Template
