import { Box } from "@chakra-ui/core"
import React, { useEffect, useState } from "react"
import { Breadcrumb } from "./Breadcrumb"

export const Breadcumbs = () => {
  const [parts, setParts] = useState<string[]>([])

  useEffect(() => {
    setParts(window.location.href.split("//")[1].split("/"))
  }, [])

  const baseUrl = parts[0]

  let customLink = `https://${baseUrl}`

  const crumbs = parts.map((url: string, i: number) => {
    if (i !== 0) {
      customLink = customLink + "/" + url
      return (
        <Breadcrumb
          link={customLink}
          isFinal={i === parts.length - 1}
          name={url}
        />
      )
    }
  })

  return (
    <Box display="flex" justifyContent="center">
      <Breadcrumb link="/" isFinal={false} name="Suomi" />
      {crumbs}
    </Box>
  )
}
